.Header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: $headSize;
  overflow: hidden;
  z-index: $z-index-mobileNav;
  text-align: center;
  @include title;
  text-transform: uppercase;

  @include breakpoint('small') {
    display: none;
  }

  &__item {
    width: 25%;
    height: $headSize;
    height: auto;
    background: $white;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3px;

    &--about {
      background: $about;
    }
    &--explore {
      background: $explore;
    }
    &--compare {
      background: $compare;
    }
  }
}
