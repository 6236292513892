.ExploreFilter {
  position: relative;
  width: auto;
  left: -2px;
  right: -2px;
  display: flex;
  flex-wrap: wrap;
  overflow: visible;
  padding: 80px $margin 20px;
  z-index: 1;

  @include breakpoint('small') {
    padding: 60px $margin 25px;
  }

  @include backshadow($explore);

  &__menu {
    display: flex;
    flex-wrap: wrap;
    // margin: 0 -2px;
    @include breakpoint('small') {
      width: 100%;
    }
  }
  &__btn {
    // margin: 2px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;

    @include breakpoint-max('small') {
      display: none;
      &.is-active {
        display: flex;
      }
    }
  }
  li {
    padding: 2px;
    text-transform: capitalize;
  }
  &__btn {
    display: flex;
    padding-bottom: 4px;
  }

  &__btnDesk {
    @include breakpoint-max('small') {
      display: none;
    }
  }

  &__menuMobile {
    display: flex;
    flex-wrap: wrap;
    &-item {
      padding: 2px;
    }
    @include breakpoint('small') {
      display: none;
    }
  }

  &__compare {
    width: 100%;
    display: flex;
    padding-bottom: $margin * 2;
    align-items: flex-start;

    &__back {
      z-index: 1;
      background: transparent;
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
    }

    &__items {
      width: auto;
      margin-right: 4px;
      position: relative;
      z-index: 2;

      .is-open & {
        min-width: 180px;
        display: block;
      }
    }
    &__items-inner {
      display: none;
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      background: $explore;
      text-transform: capitalize;
      border-bottom: 1px solid $black;

      ul {
        max-height: 140px;
        overflow: scroll;
        display: none;
        &.is-active {
          display: block;
        }
      }
      li {
        margin-top: -1px;
        padding: 0;
      }

      .is-open & {
        display: block;
      }
    }
    &__selected {
      display: flex;
      flex-wrap: wrap;
      text-transform: capitalize;
      .Button {
        margin-right: 4px;
        margin-bottom: 4px;
      }
    }
  }
}
