.Search {
  &__main {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: $z-index-search;
    padding: $margin;
    @include col('width', 3);

    @include breakpoint-max('small') {
      width: 100%;
      max-width: 300px;
    }
  }
  &__recent {
    position: absolute;
    bottom: 0;
    padding: $margin;
    @include breakpoint('small') {
      padding: $margin * 2 $margin;
    }

    h2 {
      text-transform: uppercase;
      margin-bottom: $margin;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -2px;
      li {
        padding: 2px;
      }
    }
  }

  &__mobile-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    @include backshadow($white);
    z-index: 1;
    padding: 80px $margin 20px;
    display: flex;
    .Button {
      margin-right: 4px;
    }
    @include breakpoint('small') {
      display: none;
    }
  }

  &__mobile-close.Button {
    margin-left: auto;
    position: relative;
    width: 28px;
    border: 0;
    .SearchForm__close {
      right: 0;
      opacity: 1;
    }
  }

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__clear {
    display: block;
    position: absolute;
    z-index: $z-index-search;
    width: 90px;
    height: 20px;
    top: 10px;
    left: 0;
    cursor: pointer;
  }
}
