.TextSlider {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  position: relative;
  display: block;
  height: 22px;
  span {
    display: inline-block;
    position: relative;
    padding-right: $margin;
    white-space: nowrap;
  }
  div {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    white-space: nowrap;
  }
  &__main {
    transform: translate3d(0%, 0, 0);
    animation: marquee 15s linear infinite;
    animation-play-state: running;
  }
  &__copy {
    left: 100%;
  }
}

@keyframes marquee {
  0% {
    transform: translate3d(0%, 0, 0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
