// Z-index Setup

$next-z-index: 0;

/// Z-index incrementer
/// @group settings
/// @requires {variable} next-z-index
/// @return {Number}

@function get-next-z-index() {
  $next-z-index: $next-z-index + 1 !global;
  @return $next-z-index;
}

$z-index-main: get-next-z-index();
$z-index-nav: get-next-z-index();
$z-index-search: get-next-z-index();
$z-index-mobileNav: get-next-z-index();
