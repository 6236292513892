.notification {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  width: 100%;
  max-width: 450px;
  gap: 10px;
  padding: 10px;

  &-item {
    border: 1px solid $black;
    background: $white;
    padding: 5px 10px 8px;
    pointer-events: all;
    position: relative;
    padding-right: 20px;
  }
  &-close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    width: 15px;
    height: 15px;
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0%;
      width: 100%;
      height: 1px;
      background: $black;
      transform: translateY(-50%) rotate(45deg);
    }
    &:after {
      transform: translateY(-50%) rotate(-45deg);
    }
  }
}
