.CompareForm {
  width: 100%;
  display: flex;
  padding: 0 $margin;
  justify-content: center;

  @include breakpoint-max('small') {
    flex-wrap: wrap;
    max-width: 240px;
    margin: 0 auto;
  }

  &__input {
    text-align: center;
    @include title;
    @include col('width', 2);

    @include breakpoint-max('small') {
      width: 100%;
    }
  }
  &__mid {
    @include col('width', 1);
    text-align: center;
    @include title;
    @include breakpoint-max('small') {
      width: 100%;
      padding: $margin * 2 0 0;
    }
  }
  .SearchForm__button {
    @include breakpoint-max('small') {
      padding-top: $margin * 2;
    }
  }
}
