.Button {
  @include font($size: 18, $line: 18);
  display: block;
  height: 28px;
  background: transparent;
  padding: 2px 10px 5px;
  border: 1px solid $black;
  display: flex;
  cursor: pointer;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.is-active {
    background: $black;
    color: $white;
  }
  @include hover {
    background: $black;
    color: $white;
  }

  &--reverse {
    color: $white;
    background: $black;
    border-color: $black;

    &.is-active {
      background: transparent;
      color: $black;
    }
    @include hover {
      background: transparent;
      color: $black;
    }
  }

  &--explore {
    color: $white;
    background: $black;
    border-color: $black;
    cursor: default;

    &.is-active {
      color: $white;
      background: $black;
    }
    @include hover {
      color: $white;
      background: $black;
    }
  }

  &--search {
    @include breakpoint-max('small') {
      color: $white;
      background: $black;
      border-color: $black;

      &.is-active {
        background: transparent;
        color: $black;
      }
      @include hover {
        background: transparent;
        color: $black;
      }
    }
  }
  &--close {
    @include close(15px);
    margin-left: 3px;
    margin-top: 3px;
  }

  &--loading {
    // opacity: 0.5;
    cursor: default;

    &:after {
      content: '...';
      animation: dots 1s steps(5, end) infinite;
      width: 16px;
      text-align: center;
      display: block;
    }

    &.is-active {
      background: $grey;
      border-color: $grey;
    }
    @include hover {
      background: $grey;
      border-color: $grey;
    }
  }
}

@keyframes dots {
  0%,
  20% {
    content: '.';
  }
  40% {
    content: '..';
  }
  60% {
    content: '...';
  }
  80%,
  100% {
    content: '.';
  }
}
