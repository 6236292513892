.ExploreItem {
  // padding: $margin;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  z-index: 0;

  &__nav {
    position: relative;
    display: block;
    @include col('width', 5);
    @include col('margin-left', 2);
    margin-bottom: 20px;

    @include breakpoint-max('small') {
      width: 100%;
      margin-left: 0;
    }

    ul {
      padding-left: 40px;
      padding-bottom: 5px;
      display: flex;
      @include breakpoint-max('small') {
        padding-left: 0px;
      }
    }
    li {
      position: relative;
      padding: 2px;
    }
    .Button {
      cursor: pointer;
    }
  }
  &__datepicker {
    position: absolute;
    top: 100%;
    left: 2px;
    z-index: 1;
    height: 0;
    overflow: hidden;
    &.is-open {
      height: auto;
    }
  }

  &__text {
    width: 100%;
    padding-bottom: $margin * 2;
  }

  &__chart,
  &__pie {
    width: 100%;
    display: flex;
    padding-bottom: $margin * 2;
    background: $explore;
  }

  //calendar range
  .react-daterange-picker__calendar {
    width: 200px;
  }
  .react-calendar__month-view__days__day--weekend {
    color: $black;
  }
  .react-calendar {
    @include range;
    background: $explore;
    border: 1px solid $black;
    padding: 4px;

    abbr {
      text-decoration: none;
    }
    &__navigation {
      margin-bottom: 0;
      height: 20px;
      button {
        @include range;
        min-width: 20px;
        @include hover {
          background: transparent;
        }
        &:disabled {
          color: $black;
          background: transparent;
          opacity: 0.2;
        }
      }
      &__prev2-button,
      &__next2-button {
        display: none;
      }
      &__label {
        pointer-events: none;
      }
    }

    .react-calendar__tile {
      height: 20px;
      // width: 25px;
      padding: 0;
      margin: 2px 0;
      @include range;

      &:disabled {
        color: $black;
        background: transparent;
        opacity: 0.2;
      }

      &--hover {
        background: rgba($black, 0.5);
        color: $black;
      }

      &--range,
      &--now {
        background: $black;
        color: $white;
      }
      &--rangeStart,
      &--hoverStart,
      &--now {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      &--rangeEnd,
      &--hoverEnd,
      &--now {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}
