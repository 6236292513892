.SearchForm {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  @include breakpoint-max('small') {
    flex-wrap: wrap;
  }
  &__input {
    display: flex;
    width: 100%;
    position: relative;
    &.has-label {
      padding-left: 26px;
    }
    label {
      width: 16px;
      height: auto;
      margin-right: $margin;
      display: flex;
      align-items: center;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      svg {
        width: 16px;
        height: auto;
        fill: $black;
        display: block;
      }
    }
    input {
      @include title;
      padding: 0 0 5px;
      border-bottom: 1px solid $black;
      width: 100%;
      @include placeholder {
        color: rgba($black, 0.4);
      }
    }
  }

  &__close {
    width: 16px;
    height: 16px;
    display: block;
    position: relative;
    cursor: pointer;

    position: absolute;
    right: 9px;
    top: 50%;
    margin-top: -8px;

    opacity: 0.4;
    @include hover {
      opacity: 1;
    }

    &:before,
    &:after {
      position: absolute;
      left: 50%;
      margin-left: -1px;
      content: ' ';
      height: 100%;
      width: 2px;
      background-color: $black;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  &__button {
    padding-left: 18px;

    @include breakpoint-max('small') {
      width: 100%;
      padding-left: 0;
      padding-top: 20px;
      .Button {
        margin: 0 auto;
      }
    }

    &.hide-submit {
      display: none;
    }
  }
}
