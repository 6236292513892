/* ----------------
This file and all files in the utility folder should only include code that is not project specific
Put project specific code in the base/init folder
---------------- */

/* -----
Cursor
----- */
@mixin cursor($name, $stack: default) {
  cursor: $stack;
  cursor: url('#{$img_path}cursors/cursor-#{$name}.png') 20 20, $stack;
  cursor: -webkit-image-set(
        url('#{$img_path}cursors/cursor-#{$name}.png') 1x,
        url('#{$img_path}cursors/cursor-#{$name}@2x.png') 2x
      )
      20 20,
    $stack;
  .is-safari-lt9 & {
    cursor: url('#{$img_path}cursors/cursor-#{$name}.png') 20 20, $stack;
  }
}

/* -----
selection style
----- */
@mixin selection() {
  &::-moz-selection {
    @content;
  }
  &::selection {
    @content;
  }
}

/* -----
Clearfix
----- */
@mixin clearfix {
  &::before,
  &::after {
    display: table;
    content: ' ';
  }

  &::after {
    display: block;
    clear: both;
    height: 1px;
    margin-top: -1px;
    visibility: hidden;
  }

  & {
    zoom: 1;
  }
}

/* -----
Placeholder style
----- */
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &::-ms-input-placeholder {
    @content;
  }
}

/* -----
ScrollBar
----- */
@mixin hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

/* -----
Box Shadow
----- */
@mixin box-shadow($color: #fff) {
  box-shadow: -1px -0px 0px $color, -0px 1px 0px $color, -2px 1px 0px $color,
    -1px 2px 0px $color, -3px 2px 0px $color, -2px 3px 0px $color;
}

/* -----
underline
----- */
@mixin underline($color: $black) {
  position: relative;
  display: inline-block;
  z-index: 1;
  &:before {
    content: '';
    border-bottom: 1px solid $color;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0.05em;
    z-index: -1;
  }
}

/* -----
back shadow
----- */
@mixin backshadow($color: '#fff') {
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 20px;
    background: $color;
    z-index: -1;
  }

  &:after {
    height: 20px;
    top: auto;
    bottom: 0;
    background: linear-gradient(0deg, rgba($color, 0) 0%, rgba($color, 1) 100%);
  }
}

/* -----
hover
----- */
@mixin hover {
  @media (hover: hover) {
    // transition: color 0.2s linear, border 0.2s linear, background 0.2s linear;
    &:hover {
      @content;
    }
  }
}
//
@mixin close($size: 20px, $line: 2px) {
  position: relative;
  width: $size;
  height: $size;

  &:before,
  &:after {
    position: absolute;
    left: $size * 0.5;
    content: ' ';
    height: $size;
    width: $line;
    background-color: currentColor;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}
