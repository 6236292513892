/*
* COLORS
*/

$black: #000;
$white: #fff;
$green: #dce9d5;
$greenLight: #e4eedc;
$yellow: #f7eba9;
$yellowLight: #fef1d0;
$pink: #dfbab1;
$pinkLight: #edcfc8;
$grey: #ebebeb;
$greyLight: #f5f5f5;
$greyDark: #dcdcdc;

$search: #ffffff;
$searchHover: #f5f5f5;
$compare: #f4f0f1;
$compareHover: #e5dcdd;
$explore: #e8e1e2;
$exploreHover: #d5c3c5;
$about: #ddd2d4;
$aboutHover: #c5aaad;

$background: $white;

$margin: 10px;
$gridUnits: 9;

$titleWidth: 110px;
$headSize: 34px;

/* -----
Grid Col ViewPort
----- */
@mixin col($property, $unit) {
  #{$property}: (100 * $unit) / $gridUnits * 1%;
}
