.About {
  display: flex;

  @include breakpoint-max('small') {
    @include font($size: 15, $line: 19);
  }

  p:not(:last-child),
  h1:not(:last-child),
  ul:not(:last-child) {
    margin-bottom: 1em;
  }

  @include breakpoint-max('small') {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 0 $margin 0;
    flex-wrap: wrap;
    padding-top: $headSize + $margin * 2;
  }

  &__inner {
    position: relative;
    width: 100%;
    padding-bottom: $margin * 2;
    @include breakpoint('small') {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      overflow-y: scroll;
      padding: 90px $margin $margin * 2;
    }
  }

  &__main {
    @include breakpoint('small') {
      @include col('width', 5);
    }

    a {
      text-decoration: underline;
      @include hover {
        text-decoration: none;
      }
    }
  }
  &__sidebar {
    @include breakpoint('small') {
      @include col('width', 3);
      @include col('left', 6);
    }
    a {
      text-decoration: underline;
      @include hover {
        text-decoration: none;
      }
    }
  }
}
