.Explore {
  &__filter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  &__filter-space {
    display: block;
    width: 100%;
    overflow: hidden;
    height: auto;
  }

  &__inner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    padding: 0 0 $margin;
  }

  &__intro {
    padding: 0 $margin $margin * 4;
    width: 100%;

    @include breakpoint-max('small') {
      @include font($size: 15, $line: 19);
    }

    @include breakpoint('small') {
      @include col('width', 5);
    }

    p:not(:last-child),
    h1:not(:last-child),
    ul:not(:last-child) {
      margin-bottom: 1em;
    }
    a {
      text-decoration: underline;
      @include hover {
        text-decoration: none;
      }
    }
  }
}

.ExploreSection {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  overflow: hidden;
  &--loading {
    padding: $margin;
    text-align: center;
  }

  &__YS {
    min-height: 100vh;
  }

  & > div {
    padding: $margin;
  }
}
