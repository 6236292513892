.Compare {
  &__inner {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    @include breakpoint-max('small') {
      padding-top: $headSize;
    }
  }
  &__search {
    width: 100%;
    position: relative;
  }
  &__chart {
    position: relative;
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    margin-top: $margin;
  }
}
