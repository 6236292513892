.ChartPie {
  mix-blend-mode: multiply;
  position: relative;
  width: 100%;
  display: flex;
  margin-bottom: $margin;
  flex-wrap: wrap;

  &--compare {
    h3 {
      display: none;
    }
    @include breakpoint('small') {
      max-width: 300px;
      margin-left: 0;
      padding-left: 0;
      width: 100%;
    }
  }

  h3 {
    text-transform: uppercase;
    padding-left: 40px;
    padding-bottom: 5px;
  }

  &__legend {
    position: relative;
    display: block;

    @include col('width', 2);

    .ChartPie--compare & {
      position: absolute;
      left: 100%;
      width: 100%;
    }

    @include breakpoint-max('small') {
      width: 100%;

      .rv-discrete-color-legend {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: $margin * 2;
      }
      .rv-discrete-color-legend-item {
        padding: ($margin * 0.5) $margin * 2;
        padding-left: 0;
        margin-bottom: $margin;
      }
    }

    .rv-discrete-color-legend-item {
      padding-left: 0;
      color: $black;
    }
  }

  &__main {
    position: relative;
    display: block;
    @include col('width', 5);
    @include col('margin-left', 2);

    .ChartPie--compare & {
      width: 100%;
      margin-left: 0;
    }

    @include breakpoint-max('small') {
      width: 100%;
      margin-left: 0;
    }
  }

  &__inner {
  }
  &__hint {
    position: absolute;
    pointer-events: none;
  }

  &__content {
    max-width: 300px;
    width: 100%;
    display: block;
    margin: 0 auto;
    position: relative;
    &:before {
      content: '';
      display: block;
      position: relative;
      width: 100%;
      padding-bottom: 100%;
    }
  }
  &__chart {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__hint-inner {
    padding: $margin;
    background: $black;
    color: $white;
    text-align: center;
    white-space: nowrap;
    @include text;
    .Compare & {
      color: $white;
    }
  }
  .rv-xy-plot__inner {
    overflow: visible;
  }
  .rv-discrete-color-legend-item__color {
    overflow: visible;
  }
  .rv-radial-chart .rv-xy-plot__series--label {
    font-size: 12px;
    background: $white;
  }
}

.labelChartPie {
  &--0 {
    fill: url(#tx00) !important;
    .has-value & {
      opacity: 0.5 !important;
    }
    .value--1 & {
      opacity: 1 !important;
    }
  }
  &--1 {
    fill: url(#tx01) !important;
    .has-value & {
      opacity: 0.5 !important;
    }
    .value--2 & {
      opacity: 1 !important;
    }
  }
  &--2 {
    fill: url(#tx02) !important;
    .has-value & {
      opacity: 0.5 !important;
    }
    .value--3 & {
      opacity: 1 !important;
    }
  }
  &--3 {
    fill: url(#tx03) !important;
    .has-value & {
      opacity: 0.5 !important;
    }
    .value--4 & {
      opacity: 1 !important;
    }
  }
  &--4 {
    fill: url(#tx04) !important;
    .has-value & {
      opacity: 0.5 !important;
    }
    .value--5 & {
      opacity: 1 !important;
    }
  }
  &--5 {
    fill: url(#tx05) !important;
    .has-value & {
      opacity: 0.5 !important;
    }
    .value--6 & {
      opacity: 1 !important;
    }
  }
  &--6 {
    fill: url(#tx06) !important;
    .has-value & {
      opacity: 0.5 !important;
    }
    .value--7 & {
      opacity: 1 !important;
    }
  }
  &--7 {
    fill: url(#tx07) !important;
    .has-value & {
      opacity: 0.5 !important;
    }
    .value--8 & {
      opacity: 1 !important;
    }
  }
  &--8 {
    fill: url(#tx08) !important;
    .has-value & {
      opacity: 0.5 !important;
    }
    .value--9 & {
      opacity: 1 !important;
    }
  }
  &--9 {
    fill: url(#tx09) !important;
    .has-value & {
      opacity: 0.5 !important;
    }
    .value--10 & {
      opacity: 1 !important;
    }
  }
  &--10 {
    fill: url(#tx10) !important;
    .has-value & {
      opacity: 0.5 !important;
    }
    .value--11 & {
      opacity: 1 !important;
    }
  }
  &--11 {
    fill: url(#tx11) !important;
    .has-value & {
      opacity: 0.5 !important;
    }
    .value--12 & {
      opacity: 1 !important;
    }
  }
}

.labelChartPie {
  &--0--stroke {
    stroke: url(#tx00) !important;
  }
  &--1--stroke {
    stroke: url(#tx01) !important;
  }
  &--2--stroke {
    stroke: url(#tx02) !important;
  }
  &--3--stroke {
    stroke: url(#tx03) !important;
  }
  &--4--stroke {
    stroke: url(#tx04) !important;
  }
  &--5--stroke {
    stroke: url(#tx05) !important;
  }
  &--6--stroke {
    stroke: url(#tx06) !important;
  }
  &--7--stroke {
    stroke: url(#tx07) !important;
  }
  &--8--stroke {
    stroke: url(#tx08) !important;
  }
  &--9--stroke {
    stroke: url(#tx09) !important;
  }
  &--10--stroke {
    stroke: url(#tx10) !important;
  }
  &--11--stroke {
    stroke: url(#tx11) !important;
  }
}
