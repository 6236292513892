.Item {
  position: relative;
  width: 100%;
  display: block;
  height: auto;
  overflow: hidden;

  &--text {
    background: $greyLight;
    padding: $margin;

    .image {
      filter: grayscale(100%);
    }

    @include hover {
      background: $greyDark;
      .image {
        filter: grayscale(0%);
      }
    }
  }

  &--image {
  }

  &__title {
    @include title;
  }
  &__img {
    margin: 0 auto;
    img {
      object-fit: contain;
    }
  }
  &__link {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    display: block;
    overflow: hidden;
    margin: $margin 0;
  }
  &__desc {
    margin-top: $margin;
  }
}
