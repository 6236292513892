.image {
  // background: $greyLight;
  width: 100%;
  display: block;
  position: relative;

  &__back {
    position: relative;
    width: 100%;
    padding-bottom: 50%;
  }

  &__src {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    opacity: 0;
    transition: opacity 0.15s linear;

    .is-loaded & {
      opacity: 1;
    }
  }
}
