.Layout {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;

  &__wip {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 100;
    background: rgba(0, 0, 0, 0.5);
    color: $white;
    font-size: 2rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
  }

  &__item {
    width: $titleWidth;
    height: 100%;
    background: $white;
    position: absolute;
    overflow: hidden;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 1;

    &:not(.is-active) {
      @include hover {
        background: $greyLight;
        .Layout__nav {
          @include backshadow($greyLight);
        }
      }

      @include breakpoint-max('small') {
        display: none;
      }
    }

    &--about {
      background: $about;

      .Layout__nav {
        @include backshadow($about);
      }

      &:not(.is-active) {
        @include hover {
          background: $aboutHover;
          .Layout__nav {
            @include backshadow($aboutHover);
          }
        }
      }
    }
    &--explore {
      background: $explore;
      .Layout__nav {
        @include backshadow($explore);
      }

      &:not(.is-active) {
        @include hover {
          background: $exploreHover;

          .Layout__nav {
            @include backshadow($exploreHover);
          }
        }
      }
    }
    &--compare {
      background: $compare;
      .Layout__nav {
        @include backshadow($compare);
      }

      &:not(.is-active) {
        @include hover {
          background: $compareHover;

          .Layout__nav {
            @include backshadow($compareHover);
          }
        }
      }
    }

    &--notFound {
      background: $compareHover;
      .Layout__nav {
        @include backshadow($compareHover);
      }
    }
  }
  &__link {
    display: none;
    @include breakpoint('small') {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 4;
      display: block;
    }
  }
  &__nav {
    display: flex;
    top: $headSize;
    width: 100%;
    left: 0;

    position: absolute;
    z-index: $z-index-nav;

    @include backshadow($white);

    @include breakpoint('small') {
      width: calc(100vw - 330px - 20px);
      left: $margin;
      top: 0;
    }
  }

  &__nav-title {
    padding: $margin 0;
    // @include col('width', 1);
    width: $titleWidth - 20;
    @include title;
    text-transform: uppercase;
    text-align: center;
    z-index: 1;
    @include breakpoint-max('small') {
      display: none;
    }
  }
  &__nav-desc {
    width: 100%;
    @include title;
    padding: $margin 0;
    white-space: nowrap;
    overflow: hidden;
    @include breakpoint('small') {
      @include col('width', 5);
      padding: $margin;
    }
  }
  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0;
    opacity: 0;

    @include breakpoint('small') {
      padding-top: 46px;

      width: calc(100vw - 330px - 20px);
      left: 10px;
    }

    .is-active & {
      opacity: 1;
    }
  }
}
