/*
    Main style file
*/
/*
    Utils style file
*/

// Utility: functions
@import '0-utility/utils';
@import '0-utility/breakpoints';
@import '0-utility/easings';
@import '0-utility/typography';

// Init
@import '1-init/vars';
@import '1-init/typography';
@import '1-init/zIndex';

// Vendor: plugins
@import '2-vendor/normalize';
@import '2-vendor/react-vis.scss';
@import '2-vendor/datepicker/datepicker.scss';

// Base global styles
@import '3-base/font-face';
@import '3-base/structure';
@import '3-base/typography';
@import '3-base/base';

@import '../../utils/DevGrid/DevGrid.scss';

// MAIN STYLES views and components
@import '../../components/components.scss';
@import '../../views/views.scss';

iframe {
  display: none !important;
}
