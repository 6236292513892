/* -----
Typography, WebFonts
----- */
$font-family: 'Jungka-Regular', Helvetica, Arial, sans-serif;

/* -----
Heading mixins

! Use the minimum needed, otherwise include it with if statements !
------ */

@mixin title($with-breakpoints: true) {
  @include font($family: $font-family, $weight: 400, $size: 16, $line: 22);

  @if $with-breakpoints {
    @include breakpoint('small') {
      @include font($size: 18, $line: 22);
    }
  }
}
@mixin text($with-breakpoints: true) {
  @include font($family: $font-family, $weight: 400, $size: 13, $line: 18);

  @if $with-breakpoints {
    @include breakpoint('small') {
      @include font($size: 16, $line: 19);
    }
  }
}
@mixin range($with-breakpoints: true) {
  @include font($family: $font-family, $weight: 400, $size: 10, $line: 20);
}
