// .ExploreItem__chart {
// &.--pdfcreate {
//   position: relative;
//   width: 830px;
//   &:before {
//     content: '';
//     background: $explore;
//     display: block;
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     z-index: 1;
//   }

//   .ChartLines__legend {
//     width: 140px;
//   }

//   // .ChartLines__main {
//   //   display: none;
//   // }
//   // background: blue;
// }
// }
.ChartLines {
  position: relative;
  width: 100%;
  display: block;
  margin-bottom: $margin;

  &__wrap {
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    background: $explore;
  }

  // .ChartLines__main-pdf {
  //   width: 690px;
  //   height: 0;
  //   overflow: hidden;
  // }

  // .--pdfcreate & {
  //   // background: red;
  //   .ChartLines__main-pdf {
  //     opacity: 1;
  //     height: auto;
  //   }
  // }

  svg {
    overflow: visible;
  }

  h3 {
    text-transform: uppercase;
    padding-left: 40px;
    padding-bottom: 5px;
  }

  &__main {
    position: relative;
    display: block;
    @include col('width', 6);

    @include breakpoint-max('small') {
      width: 100%;
    }
  }
  &__inner {
    position: relative;
    width: 100%;
    display: block;
    padding-bottom: 30px;
    &:before {
      content: '';
      width: 100%;
      padding-bottom: 60%;
      display: block;
    }
    & > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__legend-img {
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__legend {
    position: relative;
    display: block;

    @include col('width', 2);
    &--pdf {
      width: 100%;
      position: relative;
      .rv-discrete-color-legend {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: $margin * 2;
      }
      .rv-discrete-color-legend-item {
        padding: ($margin * 0.5) $margin * 2;
        padding-left: 0;
      }
    }
    @include breakpoint-max('small') {
      width: 100%;
      .rv-discrete-color-legend {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: $margin * 2;
      }
      .rv-discrete-color-legend-item {
        padding: ($margin * 0.5) $margin * 2;
        padding-left: 0;
      }
    }

    .rv-discrete-color-legend-item {
      padding-left: 0;
      color: $black;
    }
  }
}
