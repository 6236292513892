.SearchItems {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &--text {
    @include breakpoint-max('medium') {
      .active--image & {
        left: 100vw;
      }
    }
    @include breakpoint('small') {
      @include col('width', 6);
    }

    & .SearchItems__item {
      @include breakpoint('small') {
        width: 50%;
      }
    }
  }

  &--image {
    @include breakpoint-max('medium') {
      .active--text & {
        left: 100vw;
      }
    }
    @include breakpoint('small') {
      @include col('left', 6);
      @include col('width', 3);
    }
  }

  &__head {
    @include backshadow($white);
    text-align: center;
    z-index: 1;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 65px $margin 20px;
    text-transform: uppercase;
    pointer-events: none;

    @include breakpoint-max('small') {
      display: none;
    }

    h2 {
      z-index: 1;
      position: relative;
    }
  }

  &__inner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    padding: 100px 0 $margin;
    @include breakpoint-max('small') {
      padding-top: 120px;
    }
  }
  &__grid {
    width: 100%;
    position: relative;
    display: block;
    min-height: 100vh;
  }

  &__item {
    width: 100%;
    padding: $margin * 0.5 $margin;
    @include breakpoint('small') {
      padding: $margin;
    }
  }
  &__loadmore {
    display: flex;
    justify-content: center;
    width: 100%;

    &.is-loading {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
