$datepicker__background-color: $explore !default;
$datepicker__border-color: $black !default;
$datepicker__highlighted-color: $black !default;
$datepicker__muted-color: rgba($black, 0.3) !default;
$datepicker__selected-color: $black !default;
$datepicker__text-color: #000 !default;
$datepicker__header-color: #000 !default;
$datepicker__navigation-disabled-color: lighten(
  $datepicker__muted-color,
  10%
) !default;

$datepicker__border-radius: 0 !default;
$datepicker__day-margin: 0 !default;
$datepicker__font-size: 10px !default;
$datepicker__font-family: $font-family !default;
$datepicker__item-size: 26px !default;
$datepicker__item-sizeLine: 20px !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-size: 5px !default;
$datepicker__triangle-size: 8px !default;
